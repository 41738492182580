import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import Arrow from "../../../elements/V2/Arrow"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../../elements/V2/Typography"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import { getAccentColor, getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  backgroundColor: Color
  superscriptText: string
  titleText: string
  descriptionText: string
  imageUrl: string
  imageAlt: string
  buttonText?: string
  buttonLink?: string
  imageRounded?: boolean
}

const Benefits: React.FC<Props> = ({
  backgroundColor,
  superscriptText,
  titleText,
  descriptionText,
  imageUrl,
  imageAlt,
  buttonText,
  buttonLink,
  imageRounded = false,
  ...props
}) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)

  return (
    <div
      ref={animationRefCallback}
      className={classNames(
        getBackgroundColorClass(backgroundColor),
        "rounded-lg-v2 col-span-full flex flex-col justify-between sm-v2:col-span-2 md-v2:col-span-3 lg-v2:col-span-6"
      )}
      {...props}
    >
      <div className="flex flex-col items-center gap-20-v2 px-20-v2 py-32-v2 md-v2:gap-24-v2 md-v2:px-24-v2 lg-v2:gap-32-v2 lg-v2:px-32-v2 lg-v2:py-48-v2">
        <div className="flex flex-col items-center gap-8-v2 md-v2:gap-16-v2">
          <Typography
            font="grotesk"
            weight="book"
            size="subscript-lg"
            color={getAccentColor(backgroundColor)}
            text={superscriptText}
            className="text-center uppercase"
          />
          <Typography
            font="grotesk"
            weight="medium"
            size="h3"
            color={getAccentColor(backgroundColor)}
            text={titleText}
            className="text-center"
            element="h3"
          />
        </div>
        <Typography
          font="grotesk"
          weight="book"
          size="body-lg"
          color={getAccentColor(backgroundColor)}
          text={descriptionText}
          className="text-center"
        />
        {buttonText && buttonLink && (
          <TextButton
            style="icon"
            color={getAccentColor(backgroundColor)}
            text={buttonText}
            linkUrl={buttonLink}
            icon={<Arrow />}
          />
        )}
      </div>

      <GatsbyStoryblokImage
        aspectRatio="16:9"
        image={imageUrl}
        alt={imageAlt}
        width={600}
        imageObjectFit="cover"
        rounded={imageRounded}
        className={imageRounded ? "rounded-t-lg-v2" : ""}
      />
    </div>
  )
}

export default Benefits
